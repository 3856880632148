import React, { memo, useCallback, useEffect, useState } from "react";
import { Grid, Select, SelectOption, TextField } from "@app/components";
import styled from "styled-components";
import { getCompanyTypes, getRegions } from "@app/api";
import { theme } from "styled-tools";

type DataErrors = {
  [key in keyof any]?: string;
};

type Region = {
  id: string;
  name: string;
};

interface Props {
  dataOfOrganization: any;
  dataParams?: any;
  dataErrors: DataErrors;
  onDataChange: (value: any[keyof any], key: keyof any) => void;
  readonly: boolean;
}

const Header = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
  gap: 16px;
  margin-bottom: 16px;
`;

const StyledHeader = styled.p`
  font-size: 14px;
  font-weight: 600;
  color: #000000;
  margin-bottom: 8px;
`;

const StyledAddressContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledDivider = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${theme("color.gray")};
  margin-top: 16px;
`;

const initialParams = {
  pageNumber: 1,
  pageSize: 10000,
  searchText: "",
};

function Step2(props: Props) {
  const { dataParams, dataErrors, readonly, onDataChange } = props;
  const [errors, setErrors] = useState<DataErrors>({});
  const [companyTypes, setCompanyTypes] = useState<SelectOption<any>[]>([]);
  const [regions, setRegions] = useState<SelectOption<any>[]>([]);

  useEffect(() => {
    setErrors(dataErrors);
  }, [dataErrors]);

  const handleChange = (value: any, name: any) => {
    onDataChange(value, name);
  };

  const loadCompanyTypes = useCallback(async () => {
    try {
      const response = await getCompanyTypes();
      const formattedData = response.data.map((companyTypes: any) => ({
        value: companyTypes.id,
        label: companyTypes.name,
        item: companyTypes,
      }));

      setCompanyTypes(formattedData);
    } catch (error) {
      console.error("Ошибка при загрузке списка: ", error);
    }
  }, []);

  const loadRegions = useCallback(async (params: any) => {
    try {
      const response = await getRegions(params);

      const formattedData: SelectOption<any>[] = response.data.map(
        (region: any) => ({
          value: region.id,
          label: region.name.trim(),
          item: region,
        })
      );

      const priorityRegions = ["Астана", "Алматы", "Шымкент", "Ташкент"];
      const priorityData: SelectOption<Region>[] = [];
      const otherData: SelectOption<Region>[] = [];

      formattedData.forEach((region: SelectOption<Region>) => {
        if (priorityRegions.includes(region.label)) {
          priorityData.push(region);
        } else {
          otherData.push(region);
        }
      });

      otherData.sort((a: SelectOption<Region>, b: SelectOption<Region>) =>
        a.label.localeCompare(b.label)
      );
      priorityData.sort(
        (a: SelectOption<Region>, b: SelectOption<Region>) =>
          priorityRegions.indexOf(a.label) - priorityRegions.indexOf(b.label)
      );

      const sortedData: SelectOption<Region>[] = [
        ...priorityData,
        ...otherData,
      ];

      setRegions(sortedData);
    } catch (error) {
      console.error("Ошибка при загрузке списка:  ", error);
    }
  }, []);

  useEffect(() => {
    loadCompanyTypes();
    loadRegions(initialParams);
  }, [loadCompanyTypes, loadRegions]);

  return (
    <Header>
      <StyledAddressContent>
        <StyledHeader>Адрес организации</StyledHeader>
        <Grid columns={2}>
          <Select<any>
            label="Регион"
            name="region"
            onChange={handleChange}
            value={dataParams.region}
            error={!!errors.region}
            helperText={errors.region}
            placeholder={"Выберите из списка"}
            valueKey="id"
            labelKey="name"
            options={regions}
            readonly={readonly}
            onClear={() => handleChange(null, "region")}
          />
          <TextField
            label="Улица"
            placeholder="Введите улицу"
            name="street"
            onChange={handleChange}
            value={dataParams.street}
            error={!!errors.street}
            helperText={errors.street}
            readOnly={readonly}
          />
        </Grid>
        <Grid columns={2} style={{ marginTop: 16 }}>
          <TextField
            label="№ дома"
            placeholder="№ дома"
            name="block"
            onChange={handleChange}
            value={dataParams.block}
            error={!!errors.block}
            helperText={errors.block}
            readOnly={readonly}
          />
          <TextField
            label="№ офиса/квартиры"
            placeholder="№ офиса/квартиры"
            name="apartment"
            onChange={handleChange}
            value={dataParams.apartment}
            readOnly={readonly}
          />
        </Grid>
      </StyledAddressContent>
    </Header>
  );
}

export default memo(Step2);
