import React, { FC, useCallback, useState } from "react";
import { Button, DocumentFile } from "@app/components";
import { FileObject } from "@app/models";
import { APIResponse, getFileById } from "@app/api";
import { AxiosError } from "axios";
import { getAxiosErrorMessage, joinValues } from "@app/helpers";
import { IconFileFilled24 } from "@app/icons";
import { useNotification } from "@app/providers";
import { downloadFile } from "../../helpers/downloadFile";
import { PopupMenu } from "@app/common";
import styled from "styled-components";

type Props = {
  files: FileObject[] | null;
  documentDefaultName?: string;
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px 16px 24px;

  button:last-child {
    margin-top: 16px;
  }
`;

const TableRowFile: FC<Props> = (props) => {
  const { files, documentDefaultName } = props;
  const { showNotification } = useNotification();

  const [fileLoading, setFileLoading] = useState(false);

  const downloadDocument = useCallback(
    async (fileId: string, fileName?: string) => {
      try {
        setFileLoading(true);
        const res = await getFileById(fileId);
        const url = window.URL.createObjectURL(res);
        const defaultName = joinValues(
          ["Документ", documentDefaultName ?? null],
          " "
        );
        downloadFile(url, fileName || defaultName);
        setFileLoading(false);
      } catch (e) {
        showNotification({
          message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
          variant: "error",
        });
        setFileLoading(false);
      }
    },
    [documentDefaultName, showNotification]
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const downloadFiles = useCallback(async () => {
    if (files?.length) {
      const downloads = files.map((item) => {
        return downloadDocument(item.id, item?.name || "");
      });
      try {
        setFileLoading(true);
        await Promise.all(downloads);
        showNotification({
          message: "Все файлы скачаны",
          variant: "success",
        });
        setFileLoading(false);
      } catch (e) {
        setFileLoading(false);
        showNotification({
          message: "Не удалось скачать все файлы",
          variant: "error",
        });
      }
    }
  }, [downloadDocument, files, showNotification]);

  if (!files?.length) {
    return null;
  }

  return (
    <PopupMenu icon={IconFileFilled24} onClick={() => {}}>
      <Wrapper>
        {files?.map((item, index) => (
          <DocumentFile key={index} id={item.id} name={item.name || ""} />
        ))}
        <Button
          variant={"contained"}
          text={"Скачать все файлы"}
          size={"small"}
          showLoader={fileLoading}
          onClick={downloadFiles}
        />
      </Wrapper>
    </PopupMenu>
  );
};

export default TableRowFile;
